<template>
  <div>
    <!--begin::Dashboard-->
    <b-row>
      <b-col md="3" offset-md="3">
        <b-form-datepicker  v-model="start"></b-form-datepicker>
      </b-col>
      <b-col md="3">
        <b-form-datepicker locale="fr" v-model="end"></b-form-datepicker>
      </b-col>
      <b-col md="1">
        <b-button variant="success" size="sm" class="btn-icon mr-1" @click="init()"><i class="icon-md fas fa-search"></i></b-button>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <div class="card-body p-0">
            <div class="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
              <span class="symbol  symbol-50 symbol-light-success mr-2">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-xl svg-icon-success">
                    <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
                  </span>
                </span>
              </span>
              <div class="d-flex flex-column text-right">
                <span class="text-dark-75 font-weight-bolder font-size-h3">{{numberFormated(caTotal)}}€</span>
                <span class="text-muted font-weight-bold mt-2">Chiffre d'affaire</span>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="4" offset-md="4">
        <div class="card card-custom gutter-b">
          <div class="card-body p-0">
            <div class="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
              <span class="symbol  symbol-50 symbol-light-success mr-2">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-xl svg-icon-success">
                    <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
                  </span>
                </span>
              </span>
              <div class="d-flex flex-column text-right">
                <span class="text-dark-75 font-weight-bolder font-size-h3">{{numberFormated(comTotal)}}€</span>
                <span class="text-muted font-weight-bold mt-2">Commission</span>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Commerciaux</span>
            </h3>
          </div>
          <div class="card-body pt-0 pb-3">
            <div class="tab-content">
              <div class="table-responsive">
                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead>
                    <tr class="text-left">
                      <th style="min-width: 250px" class="pl-7">
                        <span class="text-dark-75">Commercial</span>
                      </th>
                      <th style="min-width: 120px">Nb Leads</th>
                      <th style="min-width: 100px">Nb Formations</th>
                      <th style="min-width: 100px">Nb Paye</th>
                      <th style="min-width: 100px">CA</th>
                      <th style="min-width: 100px" v-if="$store.role === 'isAdmin'">Frais formation</th>
                      <th style="min-width: 101px">Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(user, i) in users">
                      <tr v-bind:key="i">
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ user.prenom }} {{ user.nom }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ leads.filter(f => f.user_id === user.id).length }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ formations.filter(f => f.user_id === user.id).length }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ formations.filter(f => f.user_id === user.id && f.payer == true).length }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ numberFormated(user.caTotal) }}</span>
                        </td>
                        <td v-if="$store.role === 'isAdmin'">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ numberFormated(user.prixFormation) }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ numberFormated(user.com) }}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
      </b-col>
      <b-col md="12" v-if="$store.role === 'isAdmin'">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Leads par source</span>
            </h3>
          </div>
          <div class="card-body pt-0 pb-3">
            <div class="tab-content">
              <div class="table-responsive">
                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead>
                    <tr class="text-left">
                      <th style="min-width: 250px" class="pl-7">
                        <span class="text-dark-75">Nom</span>
                      </th>
                      <th style="min-width: 120px">Prix unitaire</th>
                      <th style="min-width: 100px">Nb recu</th>
                      <th style="min-width: 100px">Prix achat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(s, i) in parametrage.Source">
                      <tr v-bind:key="i">
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ s.titre }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ s.prix }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ leads.filter(f => f.source_id === s.id).length }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ leads.filter(f => f.source_id === s.id).length *s .prix }}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
      </b-col>
      <b-col md="6">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Status des formations</span>
            </h3>
          </div>
          <div class="card-body pt-0 pb-3">
            <div class="tab-content">
              <div class="table-responsive">
                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead>
                    <tr class="text-left">
                      <th style="min-width: 250px" class="pl-7">
                        <span class="text-dark-75">Statut</span>
                      </th>
                      <th style="min-width: 120px">Nb Formations</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(s, i) in parametrage.FormationStatus">
                      <tr v-bind:key="i">
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{s.titre}}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{formations.filter(f => f.status_id == s.id).length}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
      </b-col>
      <b-col md="6">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Status des leads</span>
            </h3>
          </div>
          <div class="card-body pt-0 pb-3">
            <div class="tab-content">
              <div class="table-responsive">
                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead>
                    <tr class="text-left">
                      <th style="min-width: 250px" class="pl-7">
                        <span class="text-dark-75">Statut</span>
                      </th>
                      <th style="min-width: 120px">Nb Formations</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(s, i) in parametrage.LeadStatus">
                      <tr v-bind:key="i">
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{s.titre}}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{leads.filter(l => l.status_id == s.id).length}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "dashboard",
  components: {
  },
  data(){
    return {
      start:null,
      end:null,
      users:[],
      leads:[],
      formations:[],
      caTotal:0,
      comTotal:0,
      parametrage:{
        FormationStatus:[],
        Source:[],
        LeadStatus:[]
      }
    }
  },
  watch:{
  },
  beforeMount() {
    let params = {
      modules: ["FormationStatus","LeadStatus",'Source']
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.FormationStatus = res.data['FormationStatus'];
        this.parametrage.LeadStatus = res.data['LeadStatus'];
        this.parametrage.Source = res.data['Source'];
      }
    });

  },
  mounted() {
    var date = new Date();
    this.start = `${date.getFullYear()}-${date.getMonth()+1}-01`;
    this.end = `${date.getFullYear()}-${date.getMonth()+1}-${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()}`;
    this.init();
  },
  methods: {
    init(){
      this.$store.back.ajax(`/statistique/dashboard?start=${this.start}&end=${this.end}`, null, (res) => {
        if(res.status === true){
          this.users = res.data.users
          this.leads = res.data.leads
          this.formations = res.data.formations
          this.caTotal = 0
          this.comTotal = 0
          this.users.forEach((item) => {
            this.caTotal += item.caTotal
            this.comTotal += item.com
          });
        }
      });
    },
    numberFormated(x) {
      x = parseInt(x);
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
